import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_API_URL

export const search = async (value=null, supplierId=null) => {
    let url = `${BaseUrl}v1/search-product?`
    url += value ? `hint=${value}` : (supplierId ? `&supplier_id=${supplierId}` : ``)
    
    return request({
        url,
    }, true)
}

export const fetchSuppliers = async () => {
    return request({
        url: `${BaseUrl}v1/suppliers`
    }, true)
}

export const getSupplierProducts = async (supplierId) => {
    return request({
        url: `${BaseUrl}v1/products?supplier_id=${supplierId}`
    }, true)
}

export const fetchBranchWarehouse = async (filters) => {
    let queryUrl = `${BaseUrl}v1/variations?q=1`

    queryUrl += filters.sku ? `&sku=${filters.sku}` : ``
    queryUrl += filters.supplier_id ? `&supplier_id=${filters.supplier_id}` : ``
    queryUrl += filters.color_ar ? `&color_ar=${filters.color_ar}` : ``
    queryUrl += filters.size ? `&size=${filters.size}` : ``

    return request({
        url: queryUrl
    }, true)
}