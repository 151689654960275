import './App.css';
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { AppRoutes } from "./routes"
import {
  QueryClient,
  QueryClientProvider
} from "react-query"
import { AuthProvider } from './utils/AuthContext'


// const theme = createTheme({ fontFamily: 'Tajawal', backgroundColor: "red" });

function App() {
  const queryClient = new QueryClient()

  return (
    <AuthProvider>
    {/* <ThemeProvider theme={theme}> */}
      {/* <div className="App"> */}
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
      </QueryClientProvider>
      {/* </div> */}
    {/* // </ThemeProvider> */}
    </AuthProvider>
  );
}

export default App;
