import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_API_URL

export const fetchReceipts = async (filters) => {
    let queryStr = `?`
    Object.entries(filters).map(([key, value]) => {
        queryStr += `${key}=${value}&`
    })
    queryStr = queryStr.substring(0, queryStr.length - 1)
    return request({
        url: `${BaseUrl}v1/sales${queryStr}`,
    }, true)
}

export const exchange = async (data, orderId) => {
    return request({
        url: `${BaseUrl}v1/exchange/${orderId}`,
        method: "PUT",
        data
    }, true)
}

export const returnOrder = async (data, orderId) => {
    return request({
        url: `${BaseUrl}v1/return-order/${orderId}`,
        method: "PUT",
        data
    }, true)
}