import Cookies from "js-cookie"
import moment from "moment"
import { fetchDiscounts } from "../services"

const getToken = () => {
    return Cookies.get("token") || null
}

const setToken = (name, value) => {
    // Cookies.set(name, value, {domain: `.stllr.io`})
    Cookies.set(name, value)
}

const signout = () => {
    Cookies.remove("token")
    window.location.href = process.env.REACT_APP_MOUDY_POS_HOME
}

const receiptPrint = async ({ date_add, customer_firstname, customer_lastname, payment_method, invoice_no, items, discount_id }) => {
    let receiptBody
    var mywindow = window.open('', 'PRINT', '');
    let date = moment(date_add)

    mywindow.document.write('<html><head><title>' + document.title + '</title><script>window.JSREPORT_CHROME_PDF_OPTIONS = {height: "800px"}</script>');
    mywindow.document.write('</head><body style="font-family: MONOSPACE; margin-top: 0px !important">');
    mywindow.document.write(`<img src="${`${process.env.PUBLIC_URL}images/logo-new-cropped.png`}" height="55" style="margin-bottom: 10px" />`)
    mywindow.document.write(`<p style="font-size: 10px; margin-bottom: 5px !important;"><b>Date: </b>${date.format("DD/MM/YYYY hh:mmA")}</p>`)
    mywindow.document.write(`<p style="font-size: 10px; margin-bottom: 5px !important; margin-top: 4px;"><b>Branch: </b>All Season Park Mall - Madinaty</p>`)
    mywindow.document.write(`<p style="font-size: 10px; margin-bottom: 5px !important; margin-top: 4px;"><b>Customer: </b>${customer_firstname} ${customer_lastname}</p>`)
    mywindow.document.write(`<p style="font-size: 10px; margin-bottom: 5px !important; margin-top: 4px;"><b>Payment: </b>${payment_method}</p>`)
    mywindow.document.write(`<p style="font-size: 10px; margin-bottom: 20px !important; margin-top: 0px;"><b>Invoice #: </b>${invoice_no}</p>`)
    receiptBody = `<table style="width: 100%; font-size: 10px;">
              <tr style="height: 30px;">
                <td style="width: 50%; font-weight: bold">Item</td>
                <td style="width: 25%; font-weight: bold">Code</td>
                <td style="width: 25%; font-weight: bold;">Price</td>
              </tr>`

    let subtotal = 0,
    discountedTotal = 0

    items.forEach((item) => {
      receiptBody += `
                  <tr style="height: 15px;">
                    <td>${item.product_description_en} - ${item.color} - ${item.size}</td>
                    <td>${item.barcode}</td>
                    <td>EGP ${Number(item.new_price).toFixed(2)}</td>
                  </tr>
                  `
      subtotal += Number(item.new_price)
      discountedTotal += Number(item.price)
    })

    receiptBody += `</table>

              <div style="width: 100%; background-color: white; height: 1px; border: dashed 1px #000; border-top: 0px; border-right: 0px; border-left: 0px;"></div>

              <table style="width: 100%; font-size: 10px;">
                      <tr style="height: 20px;">
                        <td style="width: 75%"><b>Subtotal</b></td>
                        <td>EGP ${Number(subtotal).toFixed(2)}</td>
                      </tr>
              `

    // Apply discount if any
    if (discount_id) {
      /*let discountObj = fullDiscountsInfo.filter((d) => {
        return d.id === discount_id
      })[0]*/
      let discountObj = await fetchDiscounts(discount_id)
      discountObj = discountObj.discounts[0]

      receiptBody += `
          <tr style="height: 20px;">
            <td style="width: 75%"><b>Discount</b></td>
            <td>${discountObj.is_percent ? `` : `EGP`} ${discountObj.is_bogo ? discountObj.name_en : discountObj.disc_val.toFixed(2)}${discountObj.is_percent && !discountObj.is_bogo ? `%` : ``}</td>
          </tr>
      `
    }

    receiptBody += `</table>
    
            <div style="width: 100%; background-color: white; height: 1px; border: solid 1px #000; border-top: 0px; border-right: 0px; border-left: 0px;"></div>

            <table style="width: 100%; font-size: 10px;">
                    <tr style="height: 20px;">
                      <td style="width: 75%"><b>Total</b></td>
                      <td>EGP ${Number(discountedTotal).toFixed(2)}</td>
                    </tr>
            </table>

              <br /><br />
              <div style="position: relative; height: 100px; width: 100%;">
                <img src="${`${process.env.PUBLIC_URL}images/ig_qr.png`}" height="100" style="margin: auto; position: absolute; left: 0; right: 0;" />
              </div>

              <br />

              <h6 style="text-align: center;">**Keep the receipt for refund/exchange for 30 days, make sure items are unworn and at it's original condition</h6>
              `
    mywindow.document.write(receiptBody)
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(() => {
      mywindow.print();
      mywindow.close();
    }, 1500)
}

export { getToken, setToken, signout, receiptPrint }