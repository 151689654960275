import React, { useEffect, useState } from "react"
import Cookies from "js-cookie"
import { me } from "../services";

const Auth = React.createContext()

const AuthProvider = props => {
    const [auth, setAuth] = useState(false)
    const [user, setUser] = useState({ isLoading: true })
    const token = Cookies.get("token")

    useEffect(() => {
        setAuth(token ? true : false)
    }, [auth])

    useEffect(() => {
        if (token) {
            // get employee information
            me().then(res => setUser(res.data))
                .catch(e => setUser({ error: true }))
        }
    }, [])

    if (token && user?.isLoading) {
        return <div></div>
    }

    const authContextValue = { auth, user, setAuth }
    return <Auth.Provider value={authContextValue} {...props} />
}

const useAuth = () => React.useContext(Auth);

export { AuthProvider, useAuth }

/*import React, { useState, useEffect, useContext } from "react"
import Cookies from "js-cookie"
import { me } from "../services";

const AuthContext = React.createContext()

export function useAuth () {
    return useContext(AuthContext)
    
}

export function AuthProvider (props) {
    const [authUser, setAuthUser] = useState(null)
    const [auth, setAuth] = useState(false)
    const token = Cookies.get("token")

    useEffect(() => {
        setAuth(token ? true : false)
    }, [auth])

    const value = [
        authUser,
        setAuthUser,
        auth,
        setAuth
    ]

    return (<AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>)
}*/