import React from "react"

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom"

import { getToken } from "./utils"
// import { useAuth } from "./utils/AuthContext";

import Login from "./pages/Login"
import Expenses from "./pages/Expenses"
import Warehouse from "./pages/Warehouse"
import Exchange from "./pages/Exchange"
import Reports from "./pages/Reports"
import Home from "./pages/Home"

const ProtectedRoute = ({ user, children }) => {
    if (!user) {
        return <Navigate to="/login" />
    }
  
    return children;
  };

const UnprotectedRoute = ({ user, children }) => {
    if (user) {
        return <Navigate to="/" />
    }

    return children
}

export const AppRoutes = () => {
    const user = getToken()

    return (
        <Router>
            <Routes>
                <Route path="/reports"
                    element={<ProtectedRoute user={user}> <Reports /> </ProtectedRoute>} />
                <Route path="/exchange"
                    element={<ProtectedRoute user={user}> <Exchange /> </ProtectedRoute>} />
                <Route path="/warehouse"
                    element={<ProtectedRoute user={user}> <Warehouse /> </ProtectedRoute>} />
                <Route path="/expenses"
                    element={<ProtectedRoute user={user}> <Expenses /> </ProtectedRoute>} />
                <Route path="/login"
                    element={<UnprotectedRoute user={user}> <Login /> </UnprotectedRoute>} />
                <Route path="/"
                    element={<ProtectedRoute user={user}> <Home /> </ProtectedRoute>} />
            </Routes>
        </Router>
    )
}