import React, { useState, Fragment, useEffect } from "react"

import Header from "../components/Header"
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis"
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import TextField from '@mui/material/TextField';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Button from '@mui/material/Button';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import SearchIcon from '@mui/icons-material/Search';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from '@mui/material/Divider';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import useScanDetection from 'use-scan-detection';
import Checkbox from '@mui/material/Checkbox';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Select from "react-select"
import * as Yup from "yup";
import { Formik } from "formik";
import {
    Grid,
    InputAdornment,
    Container,
    Paper,
    Box,
    Chip,
    Stack,
    CircularProgress
} from "@mui/material"
import {
    fetchReceipts,
    search,
    exchange,
    returnOrder
} from "../services"
import { receiptPrint } from "../utils";

const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin]
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function Exchange() {
    const [open, setOpen] = useState(false),
        [loading, setLoading] = useState(false),
        [filter, setFilter] = useState({}),
        // [scannedBarcode, setScannedBarcode] = useState(null),
        [receipts, setReceipts] = useState([]),
        [activeInvoice, setActiveInvoice] = useState({}),
        [exchangeOpen, setExchangeOpen] = useState(false),
        [returnOpen, setReturnOpen] = useState(false),
        [activeVariation, setActiveVariation] = useState({}),
        [exchangeBarcode, setExchangeBarcode] = useState(null),
        [exchangeVariation, setExchangeVariation] = useState({}),
        [returnDiff, setReturnDiff] = useState({}),
        [exchangePriceDiff, setExchangePriceDiff] = useState(null),
        [exchangePriceDebt, setExchangePriceDebt] = useState("")

    useScanDetection({
        onComplete: (code) => {
            // setExchangeBarcode(code)
            loadVariation(code)
            // setScannedBarcode(code)
        },
        onError: (error) => console.log(error)
    });

    // useEffect(() => {
    //     if (scannedBarcode) {
    //         loadVariation()
    //     }
    // }, [scannedBarcode])

    const loadReceipts = async () => {
        try {
            let data = await fetchReceipts(filter)
            setReceipts(data.sales)
        } catch (e) {
            if (e.data && e.data.message) alert(e.data.message)
        }
    }

    const loadVariation = async (code=null) => {
        try {
            let variation = await search(code ? code : exchangeBarcode)
            setExchangeVariation(variation.data[0])
        } catch (e) {
            if (e.data && e.data.message) alert(e.data.message)
        }
    }

    const performReturn = async () => {
        try {
            let { data } = await returnOrder({
                variation_id: activeVariation.variation_id,
                is_restocked: activeVariation.is_restocked,
                is_refunded: activeVariation.is_refunded
            }, activeInvoice.id)

            setReturnDiff(data)

            setExchangeOpen(false)
            alert("تم تعديل الفاتورة")
        }
         catch (e) {
            if (e.data && e.data.message) alert(e.data.message)
        }
    }

    const performExchange = async () => {
        try {
            let response = await exchange({
                old_variation_id: activeVariation.variation_id,
                new_variation: exchangeVariation
            }, activeInvoice.id)

            let { newTotal, oldTotal } = response
            let isDebt = Number(oldTotal) > Number(newTotal)
            setExchangePriceDebt(isDebt ? `للعميل` : `للمحل`)
            setExchangePriceDiff(isDebt ? Number(oldTotal) - Number(newTotal) : Number(newTotal) - Number(oldTotal) )

            setExchangeOpen(false)
            alert("تم تعديل الفاتورة")
        } catch (e) {
            if (e.data && e.data.message) alert(e.data.message)
        }
    }

    const renderRows = () => {
        let rows = []

        receipts.map((row, index) => {
            rows.push(
                <Fragment key={index}>
                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => {
                                    setOpen(!open)
                                    setActiveInvoice(row)
                                }}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">{row.invoice_no}</TableCell>
                        <TableCell dir="ltr">{row.formatted_order_date}</TableCell>
                        <TableCell>{row.customer_name}</TableCell>
                        <TableCell>{row.customer_phone}</TableCell>
                        <TableCell>{row.branch_name_ar}</TableCell>
                        <TableCell>
                            <Button
                                color={"success"}
                                variant="contained"
                                onClick={() => {
                                    let { date_add, customer_firstname, customer_lastname, payment_method_name_en, invoice_no, discount_id, items } = row
                                    // Show spinner and disable everything in the page
                                    setLoading(true)

                                    setTimeout(() => {
                                        receiptPrint({
                                            date_add,
                                            customer_firstname,
                                            customer_lastname,
                                            payment_method: payment_method_name_en,
                                            invoice_no,
                                            discount_id,
                                            items
                                        })
                                    }, 500)
                                    
                                    setTimeout(() => {
                                        setLoading(false)
                                    }, 500)
                                }}
                            >طباعة الفاتورة</Button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                            <Collapse in={open} timeout="auto" unmountOnExit sx={{ pb: 2 }}>
                                <Box sx={{ margin: 1 }}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        المشتريات
                                    </Typography>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: "Bold" }}>الأرتكل</TableCell>
                                                <TableCell sx={{ fontWeight: "Bold" }}>اللون</TableCell>
                                                <TableCell sx={{ fontWeight: "Bold" }}>المقاس</TableCell>
                                                <TableCell sx={{ fontWeight: "Bold" }}>السعر قبل الخصم</TableCell>
                                                <TableCell sx={{ fontWeight: "Bold" }}>السعر بعد الخصم</TableCell>
                                                <TableCell />
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {row.items.map((item, i) => (
                                                <TableRow
                                                    key={item.order_item_id}
                                                    index={i}
                                                    sx={{
                                                        backgroundColor: `#F5F5F5`,
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">{item.sku}</TableCell>
                                                    <TableCell>{item.color_ar}</TableCell>
                                                    <TableCell>{item.size}</TableCell>
                                                    <TableCell>{Number(item.new_price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</TableCell>
                                                    <TableCell>{Number(item.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</TableCell>
                                                    <TableCell>{Number(item.price) !== Number(item.new_price) && Number(item.price) > 0 ? <Chip size="small" color="error" variant="outlined" label="يوجد خصم" /> : `-`}</TableCell>
                                                    {!item.is_restocked && !item.is_refunded ? <TableCell style={{ textAlign: "left" }}>
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            color="warning"
                                                            sx={{ mr: 2 }}
                                                            onClick={() => {
                                                                setExchangeOpen(true)
                                                                setActiveVariation(item)
                                                            }}
                                                        >استبدال</Button>

                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="error"
                                                            sx={{ mr: 2, }}
                                                            onClick={() => {
                                                                setReturnOpen(true)
                                                                setActiveVariation(item)
                                                            }}
                                                        >استرجاع</Button>
                                                    </TableCell> : <TableCell sx={{
                                                        // backgroundColor: i % 2 == 0 ? `#F5F5F5` : `#FFF`,
                                                        textAlign: "center"
                                                    }}>
                                                        <Chip
                                                            color="info"
                                                            label={item.is_refunded ? `تم الارجاع` : `تم الاستبدال`}
                                                            size="small"
                                                            // startIcon={<KeyboardReturnIcon />}
                                                            
                                                        />
                                                    </TableCell>}
                                                </TableRow>
                                            ))}

                                            <TableRow
                                                key={`${row.id}_subtotal`}
                                                sx={{
                                                    backgroundColor: `#F5F5F5`,
                                                }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>المجموع</TableCell>
                                                <TableCell>{Number(row.subtotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={`${row.id}_discount`}
                                                sx={{
                                                    backgroundColor: `#FFF`,
                                                }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ fontWeight: "bold", color: "#E43A36" }}>الخصم</TableCell>
                                                <TableCell sx={{ color: "#E43A36" }}>{Number(row.discounted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</TableCell>
                                            </TableRow>
                                            <TableRow
                                                key={`${row.id}_total`}
                                                sx={{
                                                    backgroundColor: `#FFF`,
                                                }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>الاجمالي</TableCell>
                                                <TableCell>{Number(row.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </Fragment>
            )
        })

        return rows
    }

    return (
        <CacheProvider value={cacheRtl}>
            {
          loading ?

          <div className="loading-block">
          <CircularProgress />
          <h4>جاري التنفيذ</h4>
        </div>

        :

        null
        }
            <Header />

            <BootstrapDialog
                onClose={() => setReturnOpen(false)}
                open={returnOpen}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    مرتجع فاتورة رقم {activeInvoice?.invoice_no}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setReturnOpen(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>



                <DialogContent dividers>
                    <Grid container direction="row" spacing={5}>
                        <Grid item lg={8}>
                            <Typography variant="subtitle-1"></Typography>

                            <Divider textAlign="left" sx={{ mt: 2, mb: 2, color: "#F03" }}>داخل</Divider>

                            <table style={{ width: "100%", textAlign: "right" }}>
                                <thead>
                                    <tr>
                                        <th>الأرتكل</th>
                                        <th>اللون</th>
                                        <th>المقاس</th>
                                        <th>السعر</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>{activeVariation?.sku}</td>
                                        <td>{activeVariation?.color_ar}</td>
                                        <td>{activeVariation?.size}</td>
                                        <td>{Number(activeVariation?.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</td>
                                    </tr>
                                </tbody>
                            </table>

                            <Divider textAlign="left" sx={{ mt: 2, mb: 2, color: "blue" }}>التفاصيل</Divider>

                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        name="is_refunded"
                                        value={activeVariation?.is_refunded}
                                        onChange={(e) => {
                                            setActiveVariation({ ...activeVariation, is_refunded: e.target.checked ? 1 : 0 })
                                        }}
                                    />}
                                    size="large"
                                    label="تم اعادة مبلغ القطعة للعميل"
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        name="is_restocked"
                                        value={activeVariation?.is_restocked}
                                        onChange={(e) => {
                                            setActiveVariation({ ...activeVariation, is_restocked: e.target.checked ? 1 : 0 })
                                        }}
                                    />}
                                    size="large"
                                    label="القطعة المسترجعة تصلح للبيع مجدداّ"
                                />
                            </FormGroup>
                        </Grid>

                        <Grid item lg={3}>
                            <Paper elevation={0} sx={{ textAlign: "center" }}>
                                <Chip color="error" label={<h4>{activeVariation?.payment_method_name_ar}</h4>} />
                                <h5 sx={{ mb: 0 }}>فرق السعر</h5>
                                {returnDiff.oldTotal !== undefined ? <h1 sx={{ mt: 0, mb: 0 }}>{(Number(returnDiff?.oldTotal) - Number(returnDiff?.newTotal)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</h1> : <p style={{ margin: 0 }}>-</p>}
                                {returnDiff.oldTotal !== undefined ?<p>{Number(returnDiff?.oldTotal) - Number(returnDiff?.newTotal) > 0 ? `للعميل` : `للمحل`}</p> : <p style={{ mmargin: 0 }}>-</p>}
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    {returnDiff?.oldTotal !== undefined ? null : <Button
                    variant="contained"
                    color="success"
                    size="large"
                    sx={{ backgroundColor: "#0fb080" }}
                    onClick={() => performReturn()}
                    >
                        تنفيذ المرتجع
                    </Button>}
                    <Button
                        variant="outlined"
                        size="large"
                        color="error"
                        onClick={() => {
                            setReturnOpen(false)
                            setFilter({ ...filter, invoice_no: activeVariation.invoice_no})
                            /*let updatedItem = data.filter((receipts) => {
                                return receipts.filter((receipt) => {
                                    return receipt.items.filter((item) => {
                                        item.variation_id === activeVariation.variation_id
                                    })
                                })
                            })*/
                            setReturnDiff({})
                            loadReceipts()
                        }}
                    // onClick={() => cancelOrder(orderItems.id, false)}
                    >انهاء</Button>
                </DialogActions>
            </BootstrapDialog>

            <BootstrapDialog
                onClose={() => setExchangeOpen(false)}
                open={exchangeOpen}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    استبدال فاتورة رقم {activeInvoice?.invoice_no}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setExchangeOpen(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>



                <DialogContent dividers>
                    <Grid container direction="row" spacing={5}>
                        <Grid item lg={8}>
                            <Typography variant="subtitle-1"></Typography>

                            <Divider textAlign="left" sx={{ mt: 2, mb: 2, color: "#F03" }}>داخل</Divider>

                            <table style={{ width: "100%", textAlign: "right" }}>
                                <thead>
                                    <th>الأرتكل</th>
                                    <th>اللون</th>
                                    <th>المقاس</th>
                                    <th>السعر</th>
                                </thead>

                                <tbody>
                                    <td>{activeVariation?.sku}</td>
                                    <td>{activeVariation?.color_ar}</td>
                                    <td>{activeVariation?.size}</td>
                                    <td>{Number(activeVariation?.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</td>
                                </tbody>
                            </table>

                            <Divider textAlign="left" sx={{ mt: 2, mb: 2, color: "#0fb080" }}>خارج</Divider>

                            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label="باركود الاستبدال"
                                    onChange={({ target }) => setExchangeBarcode(target.value)}
                                    focused={true}
                                />

                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => loadVariation()}
                                >بحث</Button>
                            </Stack>

                            <table style={{ width: "100%", textAlign: "right" }}>
                                <thead>
                                    <th>الأرتكل</th>
                                    <th>اللون</th>
                                    <th>المقاس</th>
                                    <th>السعر</th>
                                </thead>

                                <tbody>
                                    <td>{exchangeVariation?.sku}</td>
                                    <td>{exchangeVariation?.color_ar}</td>
                                    <td>{exchangeVariation?.size}</td>
                                    <td>{Number(exchangeVariation?.new_price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</td>
                                </tbody>
                            </table>
                        </Grid>

                        <Grid item lg={3}>
                            <Paper elevation={0} fullWidth={true} sx={{ textAlign: "center" }}>
                                <h5 sx={{ mb: 0 }}>فرق السعر</h5>
                                {/* <h1 sx={{ mt: 0, mb: 0 }}>{(Number(exchangeVariation?.new_price) - Number(activeVariation?.price)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</h1>
                                <p>{Number(exchangeVariation?.new_price) > Number(activeVariation?.price) ? `للمحل` : `للعميل`}</p> */}

                                <h1 sx={{ mt: 0, mb: 0 }}>
                                    {exchangePriceDiff ? `${exchangePriceDiff.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م`: `-`}
                                </h1>
                                <p>{exchangePriceDebt ? exchangePriceDebt : `-`}</p>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                    variant="contained"
                    color="success"
                    size="large"
                    sx={{ backgroundColor: "#0fb080" }}
                    onClick={() => performExchange()}
                    >
                        تنفيذ الاستبدال
                    </Button>
                    <Button
                        variant="outlined"
                        size="large"
                        color="error"
                    onClick={() => setExchangeOpen(false)}
                    >الغاء</Button>
                </DialogActions>
            </BootstrapDialog>

            <Container disableGutters>
                <Grid container direction="row" spacing={3} sx={{ mb: 5 }}>
                    <Grid item lg={3}>
                        {/* <Paper> */}
                        <TextField
                            label="البحث برقم الفاتورة"
                            variant="outlined"
                            onKeyDown={(e) => {
                                if(e.key === "Enter") loadReceipts()
                            }}
                            // size="medium"
                            fullWidth={true}
                            onChange={({ target }) => setFilter({ invoice_no: target.value })}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <ReceiptIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>

                    <Grid item lg={2}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={loadReceipts}
                            sx={{ height: 55 }}
                            startIcon={<SearchIcon />}
                        >
                            ابحث في الفواتير
                        </Button>
                    </Grid>

                    <Grid item lg={1}>&nbsp;</Grid>

                    <Grid item lg={3}>
                        {/* <Paper> */}
                        <TextField
                            label="البحث برقم تليفةن العميل"
                            variant="outlined"
                            onChange={({ target }) => setFilter({ phone_number: target.value })}
                            onKeyDown={(e) => {
                                if(e.key === "Enter") loadReceipts()
                            }}
                            // size="medium"
                            fullWidth={true}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SmartphoneIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>

                    <Grid item lg={3}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={loadReceipts}
                            sx={{ height: 55 }}
                            startIcon={<SearchIcon />}
                        >
                            ابحث برقم الموبايل
                        </Button>
                    </Grid>
                </Grid>

                <h3>الفواتير</h3>

                {/* RECEIPTS TABLE */}
                <TableContainer component={Paper} elevation={0}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell sx={{ fontWeight: "Bold" }}># الفاتورة</TableCell>
                                <TableCell sx={{ fontWeight: "Bold" }}>التاريخ</TableCell>
                                <TableCell sx={{ fontWeight: "Bold" }}>اسم العميل</TableCell>
                                <TableCell sx={{ fontWeight: "Bold" }}>رقم الموبايل</TableCell>
                                <TableCell sx={{ fontWeight: "Bold" }}>الفرع</TableCell>
                                <TableCell sx={{ fontWeight: "Bold" }}>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderRows()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </CacheProvider>
    )
}

export default Exchange