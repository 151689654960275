import React from "react"
import {
    CircularProgress,
    Box,
} from "@mui/material"

export default function Spinner () {
    return (
        <Box
            fullWidth
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
            }}
        >
            <CircularProgress />
        </Box>
    )
}