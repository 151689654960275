import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import {
  Box,
  TextField,
  Container,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Grid,
  Button,
  IconButton,
  InputAdornment,
  Card,
  CardContent,
  Table,
  TableBody,
  Paper,
  TableContainer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment/moment";
import AsyncCreatableSelect from 'react-select/async-creatable';
import * as Yup from "yup";
import { useQuery } from "react-query";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import rtlPlugin from "stylis-plugin-rtl";
import AsyncSelect from 'react-select/async';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { prefixer } from "stylis";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, OnChangeValue } from "react-select";
import {
  search,
  fetchSuppliers,
  computeCartTotal,
  addCustomer,
  searchCustomers,
  fetchBranchInfo,
  submitPurchase,
  fetchPaymentMethods,
  fetchDiscounts
} from "../services";
import { Formik } from "formik";
import useScanDetection from 'use-scan-detection';
import Spinner from "../components/Spinner";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(5),
    paddingBottom: 20,
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#6FA2DE",
    },
    secondary: {
      main: "#53A37E",
    },
  },
});

function createData(
  sku: string,
  barcode: string,
  description: number,
  price: number,
  actions: number
) {
  return { sku, barcode, description, price, actions };
}

function Home() {
  const handleChange = (
    newValue: OnChangeValue<ColourOption, false>,
    actionMeta: ActionMeta<ColourOption>
  ) => {
    // Show popup to complete new customer info
    if (actionMeta.action === "create-option") {
      setNewCxMobile(newValue.value);
      handleClickOpen();
    } else {
      setCustomer({
        firstname: newValue.label.split(" - ")[0].split(" ")[0],
        lastname: newValue.label.split(" - ")[0].split(" ")[1],
        phone_number: newValue.label.split(" - ")[1],

      })
    }
  },
    handleInputChange = (inputValue: any, actionMeta: any) => {
      //   console.group("Input Changed");
      //   console.log(inputValue);
      //   console.log(`action: ${actionMeta.action}`);
      //   console.groupEnd();
    },
    /*customersList = [
      {
        label: "Ahmed Mustafa - 01069666220",
        value: 1,
      },
      { label: "John Doe - 01058774553", value: 2 },
    ],*/
    styles = {
      control: (base) => ({
        ...base,
        fontWeight: 200,
        fontSize: 13,
      }),
      menu: (base) => ({
        ...base,
        fontWeight: 200,
      }),
    };

  const { data: branchInfo, isLoading: isBranchInfoLoading } = useQuery(
    "branchInfo",
    fetchBranchInfo,
    {
      select: (data) => data.branches,
    }
  );

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [newCxMobile, setNewCxMobile] = useState("");
  const [customer, setCustomer] = useState(null);
  const [discountId, setDiscountId] = useState(null);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [paymentMethodStr, setPaymentMethodStr] = useState(null)
  const [invoiceNumber, setInvoiceNumber] = useState(null)
  const [receiptItems, setReceiptItems] = useState([])
  const [searchHint, setSearchHint] = useState("")
  const [scannedBarcode, setScannedBarcode] = useState(null)
  const searchRef = useRef(null)
  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState([]);
  const [merchantCopyConfirmationShow, setMerchantCopyConfirmationShown] = useState(false)
  const [fullDiscountsInfo, setFullDiscountsInfo] = useState([])
  const [discounts, setDiscounts] = useState([])

  const clearReceipt = () => {
    setCartItems([])
    setNewCxMobile("")
    setCustomer(null)
    setDiscountId(null)
    setPaymentMethodId(null)
    setPaymentMethodStr(null)
    setInvoiceNumber(null)
    setScannedBarcode(null)
    setCartTotal([])
  }

  useScanDetection({
    onComplete: async (code) => {
      let prod = await search(code)
      // console.log(prod.data)
      addToCart(prod.data[0])
      // setScannedBarcode(code)
    },
    onError: (error) => console.log(error)
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (receiptItems.length) {
      printReceipt()
      // console.log(receiptItems)
    }
  }, [receiptItems])

  const placeOrder = async () => {
    /*let variation_ids = cartItems.map((i) => {
      return i.variation_id;
    });*/



    if (customer === null) {
      alert("برجاء ادخال بيانات العميل")
    } else if (!cartItems.length) alert("برجاء اضافة منتجات الي الفاتورة")
    else if (paymentMethodStr === null) alert("برجاء تحديد طريقة الدفع")
    else {
      setLoading(true)

      setTimeout(async () => {
        let data = {
          // items: variation_ids,
          items: cartItems,
          payment_method_id: paymentMethodId,
          discount_id: discountId,
          firstname: customer.firstname,
          lastname: customer.lastname,
          phone_number: customer.phone_number,
        };
  
        try {
          let response = await submitPurchase(data);
          // printReceipt(response.invoice_number)
          setInvoiceNumber(response.invoice_number)
          setReceiptItems(response.items)
        } catch (e) {
          if (e.data && e.data.message) alert(e.data.message)
          console.log(e);
          setLoading(false)
        }
      }, 500)
    }
  };

  const removeCartItem = (variationId) => {
    let cartClone = [...cartItems]

    /*let newArray = cartClone.filter(item => {
      return item.variation_id !== variationId
    })*/

    let index = cartClone.findIndex((obj) => obj.variation_id == variationId)
    cartClone.splice(index, 1)

    setCartItems(cartClone)
  }

  const addToCart = (variation) => {
    let itemsClone = [...cartItems];
    itemsClone.push({
      barcode: variation.barcode,
      description_en: variation.description_en,
      description_ar: variation.description_ar,
      id: variation.id,
      variation_id: variation.variation_id,
      sku: variation.sku,
      color: variation.color,
      color_ar: variation.color_ar,
      size: variation.size,
      price: variation.new_price,
      /*actions: (
        <IconButton 
          onClick={() => removeCartItem(variation.variation_id)}
        >
          <DeleteIcon fontSize="small" sx={{ color: "rgb(218, 87, 87)" }} />
        </IconButton >
      ),*/
    });

    setCartItems(itemsClone);
    // localStorage.setItem("cart", JSON.stringify(itemsClone))
  };

  /*useEffect(() => {
    localStorage.removeItem("csutomerId")
  }, [])*/

  useEffect(() => {
    if (cartItems.length) {
      computeTotal(cartItems, discountId);
    }
  }, [cartItems]);

  const computeTotal = async (items, discountId) => {
    // let total = cartItems.reduce((sum, a) => sum + a.price, 0);
    // setCartTotal(total);
    try {
       let response = await computeCartTotal(items, discountId)
       setCartTotal(response.total)
    } catch (e) {
      if (e.data && e.data.message) alert("لم نتمكن من حساب الاجمالي")
      console.log(e)
    }
  };

  const { data: suppliers, isLoading } = useQuery("suppliers", fetchSuppliers, {
    select: (data) => data.suppliers,
  });

  const { data: paymentMethods, isPaymentMethodsLoading } = useQuery("payment_methods", fetchPaymentMethods, {
    select: (data) => data.paymentMethods.map((p) => {
      return { label: p.name_ar, value: p.id }
    })
  })

  /*const { data: customersList, isCustomersLoading } = useQuery("customers", fetchCustomers, {
    select: (data) => data.customers.map((c) => {
      return { label: `${c.firstname} ${c.lastname}`, value: c.id }
    })
  })*/

  const { data, isDiscountsLoading } = useQuery("discounts", () => fetchDiscounts(), {
    /*select: (data) => data.discounts.map((p) => {
      return { label: p.name_ar, value: p.id }
    }),*/
    onSuccess: (data) => {
      setFullDiscountsInfo(data.discounts)

      let discOptions = data.discounts.map((p) => {
        return { label: p.name_ar, value: p.id }
      })

      setDiscounts(discOptions)
    }
  })

  const searchBarcode = async (value) => {
    let results = await search(value);
    setProducts(results.data);
  };

  const filterBySupplier = async (supplierId) => {
    // let data = await getSupplierProducts(supplierId)
    let data = await search(null, supplierId);
    setProducts(data.data);
  };

  const renderSuppliers = () => {
    let results = [];
    suppliers.map((s) => {
      results.push(
        <Chip
          key={s.id}
          clickable
          onClick={() => {
            // Clear Barcode search box value
            // searchRef.current.value = null
            setSearchHint("")
            filterBySupplier(s.id)
          }}
          label={s.name_ar}
        />
      );
    });

    return results;
  };

  const printReceipt = (invoice_number = null) => {
    // Show spinner and disable everything in the page
    setLoading(true)

    let receiptBody
    var mywindow = window.open('', 'PRINT', '');
    let date = moment()

    mywindow.document.write('<html><head><title>' + document.title + '</title><script>window.JSREPORT_CHROME_PDF_OPTIONS = {height: "800px"}</script>');
    mywindow.document.write('</head><body style="font-family: MONOSPACE; margin-top: 0px !important">');
    mywindow.document.write(`<img src="${`${process.env.PUBLIC_URL}images/logo-new-cropped.png`}" height="55" style="margin-bottom: 10px" />`)
    mywindow.document.write(`<p style="font-size: 10px; margin-bottom: 5px !important;"><b>Date: </b>${date.format("DD/MM/YYYY hh:mmA")}</p>`)
    mywindow.document.write(`<p style="font-size: 10px; margin-bottom: 5px !important; margin-top: 4px;"><b>Branch: </b>All Season Park Mall - Madinaty</p>`)
    mywindow.document.write(`<p style="font-size: 10px; margin-bottom: 5px !important; margin-top: 4px;"><b>Customer: </b>${customer.firstname} ${customer.lastname}</p>`)
    mywindow.document.write(`<p style="font-size: 10px; margin-bottom: 5px !important; margin-top: 4px;"><b>Payment: </b>${paymentMethodStr}</p>`)
    mywindow.document.write(`<p style="font-size: 10px; margin-bottom: 20px !important; margin-top: 0px;"><b>Invoice #: </b>${invoice_number ? invoice_number : invoiceNumber}</p>`)
    receiptBody = `<table style="width: 100%; font-size: 10px;">
              <tr style="height: 30px;">
                <td style="width: 50%; font-weight: bold">Item</td>
                <td style="width: 25%; font-weight: bold">Code</td>
                <td style="width: 25%; font-weight: bold;">Price</td>
              </tr>`

    let subtotal = 0,
    discountedTotal = 0

    receiptItems.map((item) => {
      let originalItem = cartItems.filter((i) => {
        return i.variation_id == item.variation_id
      })

      receiptBody += `
                  <tr style="height: 15px;">
                    <td>${item.description_en} - ${item.color} - ${item.size}</td>
                    <td>${item.barcode}</td>
                    <td>EGP ${Number(originalItem[0].price).toFixed(2)}</td>
                  </tr>
                  `
      // subtotal += Number(originalItem[0].price).toFixed(2)
      // discountedTotal += Number(item.price).toFixed(2)
      subtotal += Number(originalItem[0].price)
      discountedTotal += Number(item.price)
    })

    receiptBody += `</table>

              <div style="width: 100%; background-color: white; height: 1px; border: dashed 1px #000; border-top: 0px; border-right: 0px; border-left: 0px;"></div>

              <table style="width: 100%; font-size: 10px;">
                      <tr style="height: 20px;">
                        <td style="width: 75%"><b>Subtotal</b></td>
                        <td>EGP ${Number(subtotal).toFixed(2)}</td>
                      </tr>
              `

    // let total = subtotal

    // Apply discount if any
    if (discountId) {
      let discountObj = fullDiscountsInfo.filter((d) => {
        return d.id === discountId
      })[0]

      receiptBody += `
          <tr style="height: 20px;">
            <td style="width: 75%"><b>Discount</b></td>
            <td>${discountObj.is_percent ? `` : `EGP`} ${discountObj.is_bogo ? discountObj.name_en : discountObj.disc_val.toFixed(2)}${discountObj.is_percent && !discountObj.is_bogo ? `%` : ``}</td>
          </tr>
      `


      // if (discountObj.is_bogo) total = subtotal
      // else if (discountObj.is_percent) total = subtotal - (subtotal * (discountObj.disc_val / 100))
      // else total = subtotal - discountObj.disc_val
    }

    receiptBody += `</table>
    
            <div style="width: 100%; background-color: white; height: 1px; border: solid 1px #000; border-top: 0px; border-right: 0px; border-left: 0px;"></div>

            <table style="width: 100%; font-size: 10px;">
                    <tr style="height: 20px;">
                      <td style="width: 75%"><b>Total</b></td>
                      <td>EGP ${Number(discountedTotal).toFixed(2)}</td>
                    </tr>
            </table>

              <br /><br />
              <div style="position: relative; height: 100px; width: 100%;">
                <img src="${`${process.env.PUBLIC_URL}images/ig_qr.png`}" height="100" style="margin: auto; position: absolute; left: 0; right: 0;" />
              </div>

              <br />

              <h6 style="text-align: center;">**Keep the receipt for refund/exchange for 30 days, make sure items are unworn and at it's original condition</h6>
              `
    mywindow.document.write(receiptBody)
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(() => {
      mywindow.print();
      mywindow.close();
    }, 1500)

    setMerchantCopyConfirmationShown(true)
  }

  const searchCx = async (customerPhone) => {
    if (customerPhone.length) {
      let response = await searchCustomers(customerPhone)

      return response.customers.map((c) => {
        return { label: `${c.firstname} ${c.lastname} - ${c.phone_number}`, value: c.id }
      })
    }
  }

  const handleMerchantCopyClose = () => {
    setMerchantCopyConfirmationShown(false);

    // Clear receipt info in state and localstorage if any...
  };

  const saveNewCustomer = async (data) => {
    try {
      // let customerId = await addCustomer(data)
      let customerData = {
        firstname: data.firstname,
        lastname: data.lastname,
        phone_number: data.phone_number,
        email: data.email,
      };
      // localStorage.setItem("csutomerId", customerData)
      setCustomer(customerData);

      handleClose();
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message);
      else console.log(e);
    }
  };

  if (isLoading || isBranchInfoLoading || isPaymentMethodsLoading || isDiscountsLoading) return <Spinner />

  return (
    <ThemeProvider theme={theme}>
      <CacheProvider value={cacheRtl}>
        {
          loading ?

          <div className="loading-block">
          <CircularProgress />
          <h4>جاري التنفيذ</h4>
        </div>

        :

        null
        }
        <Header />

        <Dialog
          open={merchantCopyConfirmationShow}
          // onClose={handleMerchantCopyClose}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              // Set 'open' to false, however you would do that with your particular code.
              handleMerchantCopyClose()
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {"طباعد نسخة  فاتورةالتاجر ؟"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              قم بتأكيد طباعة نسخة فاتورة التاجر بعد تسليم العميل نسخته اولاز
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleMerchantCopyClose}>Disagree</Button> */}
            <Button onClick={() => {
              printReceipt()
              handleMerchantCopyClose()
              clearReceipt()
            }} autoFocus>
              اطبع نسخة التاجر
            </Button>
          </DialogActions>
        </Dialog>

        <Container disableGutters>
          <Grid container direction="row" spacing={5}>
            <Grid item lg={7}>
              <Box mb={1}>
                <TextField
                  fullWidth={true}
                  type="text"
                  name="barcode"
                  value={searchHint}
                  label="البحث بالباركود أو الأرتكل"
                  onChange={({ target }) => {
                    searchBarcode(target.value)
                    setSearchHint(target.value)
                  }}
                  // variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DocumentScannerIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <Chip
                  sx={{ mt: 3 }}
                  label={`الفرع: ${branchInfo[0].name_ar}`}
                  color="error"
                  variant="outlined"
                />

                <div className="filter-chip-container is-flex aic">
                  {/* <Chip color="primary" clickable label="أبو عميرة" /> */}
                  {renderSuppliers()}
                </div>
              </Box>

              <BootstrapDialog open={open} onClose={handleClose}>
                <DialogTitle>إضافة عميل جديد</DialogTitle>
                <Formik
                  initialValues={{
                    firstname: "",
                    lastname: "",
                    phone_number: newCxMobile,
                    // email: "",
                  }}
                  validationSchema={Yup.object().shape({
                    firstname: Yup.string().required(),
                    lastname: Yup.string().required(),
                    phone_number: Yup.string().required(),
                    // email: Yup.string().email().required(),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      //   alert(JSON.stringify(values, null, 2));
                      saveNewCustomer(values);
                      setSubmitting(false);
                    }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <DialogContent>
                        <Container disableGutters>
                          <Grid container direction="row" spacing={5}>
                            <Grid item lg={6}>
                              <TextField
                                autoFocus
                                error={errors.firstname && touched.firstname}
                                helperText={
                                  errors.firstname && touched.firstname
                                    ? errors.firstname
                                    : null
                                }
                                margin="dense"
                                label="الإسم الأول"
                                name="firstname"
                                type="text"
                                fullWidth
                                value={values.firstname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="standard"
                              />
                            </Grid>

                            <Grid item lg={6}>
                              <TextField
                                error={errors.lastname && touched.lastname}
                                helperText={
                                  errors.lastname && touched.lastname
                                    ? errors.lastname
                                    : null
                                }
                                margin="dense"
                                name="lastname"
                                label="الإسم الأخير"
                                type="text"
                                fullWidth
                                value={values.lastname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="standard"
                              />
                            </Grid>

                            <Grid item lg={6}>
                              <TextField
                                error={
                                  errors.phone_number && touched.phone_number
                                }
                                helperText={
                                  errors.phone_number && touched.phone_number
                                    ? errors.phone_number
                                    : null
                                }
                                margin="dense"
                                name="phone_number"
                                // disabled={true}
                                label="رقم الموبايل"
                                value={newCxMobile}
                                type="text"
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="standard"
                              />
                            </Grid>

                            {/*<Grid item lg={6}>
                              <TextField
                                error={errors.email && touched.email}
                                helperText={
                                  errors.email && touched.email
                                    ? errors.email
                                    : null
                                }
                                margin="dense"
                                name="email"
                                label="الإيميل"
                                type="text"
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                variant="standard"
                              />
                            </Grid>*/}
                          </Grid>
                        </Container>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="error">
                          إلغاء
                        </Button>
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                        >
                          حفظ البيانات
                        </Button>
                      </DialogActions>
                    </form>
                  )}
                </Formik>
              </BootstrapDialog>

              <div className="products-grid is-flex aic fw">
                {products.map((p, index) => (
                  <Box
                    clickable
                    onClick={() => addToCart(p)}
                    key={index}
                    sx={{
                      width: 150,
                      height: 150,
                      bgcolor: "#FFF",
                      textAlign: "center",
                    }}
                    mr={2}
                    mb={2}
                  >
                    {/*<Chip
                      color="warning"
                      sx={{ marginTop: 3 }}
                      clickable
                      label={p.sku}
                    />*/}
                    <h3>{p.sku}</h3>
                    <p style={{ fontSize: 14 }}>
                      {p.department_name_ar} - {p.supplier_name_ar}
                    </p>
                    <p style={{ fontSize: 16 }}>
                      {p.color_ar} - {p.size}
                    </p>
                  </Box>
                ))}
              </div>
            </Grid>

            <Grid item lg={5}>
              <Box sx={{ position: "-webkit-sticky", position: "sticky", top: 0 }}>
                <Box mt={-4}>
                  <Divider>
                    <h2>الفاتورة</h2>
                  </Divider>
                </Box>

                <Card style={{ marginBottom: 25 }} sx={{ overflow: "visible !important" }}>
                {/* <Card style={{ marginBottom: 25 }} sx={{ overflowY: "scroll !important" }}> */}
                {/* <Card style={{ marginBottom: 25 }}> */}
                  <CardContent>
                    {/* <Box mb={2} className="is-flex aic jcc" fullwidth={true}>
                                    <h2 className="mt-5 mb-0 has-text-centered">الفاتورة</h2>
                                </Box> */}

                    <Box mb={1}>
                      <List
                        sx={{
                          width: "100%",
                          bgcolor: "background.paper",
                        }}
                      >
                        <ListItem sx={{ pr: 0 }}>
                          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <>
                              <ListItemAvatar>
                                <Avatar sx={{ bgcolor: "#ffa733" }} color={"primary"}>
                                  <PersonIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary="بيانات العميل"></ListItemText>
                            </>

                            {cartItems.length ? <Button variant="contained" startIcon={<CloseIcon />} color="error" size="medium" onClick={() => clearReceipt()}>انشاء فاتورة جديدة</Button> : null}
                          </Box>
                        </ListItem>
                        <Divider component="li" />
                      </List>
                    </Box>

                    <Box mb={2}>
                      {customer ? (
                        <>
                          <h6 style={{ marginBottom: 0 }}>
                            الاسم: {customer.firstname} {customer.lastname}
                          </h6>
                          <h6>الموبايل: {customer.phone_number}</h6>
                        </>
                      ) : (
                        /*<CreatableSelect
                          styles={styles}
                          placeholder={"البحث برقم تليفون العميل"}
                          onChange={handleChange}
                          onInputChange={handleInputChange}
                          formatCreateLabel={(newValue) =>
                            `إضافة عميل جديد "${newValue}"`
                          }
                        />*/
                        <AsyncCreatableSelect
                          cacheOptions
                          onChange={handleChange}
                          placeholder={"البحث برقم الموبايل"}
                          defaultOptions
                          formatCreateLabel={(newValue) =>
                            `إضافة عميل جديد "${newValue}"`
                          }
                          loadOptions={searchCx}
                        />
                      )}
                    </Box>

                    <Box mb={1}>
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ bgcolor: "#ffa733" }}>
                              <ShoppingCartIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="المشتريات"></ListItemText>
                        </ListItem>
                        <Divider component="li" />
                      </List>
                    </Box>

                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">الأرتكل</TableCell>
                            <TableCell align="left">الوصف</TableCell>
                            <TableCell align="left">السعر</TableCell>
                            <TableCell align="center">&nbsp;</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {cartItems.map((row, index) => (
                            <TableRow
                              key={`${index}`}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <TableCell align="left" component="th" scope="row">
                                {row.sku}
                              </TableCell>
                              <TableCell align="left">
                                {row.color_ar} {row.size}
                              </TableCell>
                              <TableCell align="left"><label style={{ fontFamily: "MONOSPACE" }}>{row.price}ج.م</label></TableCell>
                              {/* <TableCell align="right">{row.actions}</TableCell> */}
                              <TableCell>
                                <IconButton
                                  onClick={() => removeCartItem(row.variation_id)}
                                >
                                  <DeleteIcon fontSize="small" sx={{ color: "rgb(218, 87, 87)" }} />
                                </IconButton >
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* <div
                    className="is-flex aic"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  > */}
                    <div style={{ margin: "15px 0px 15px 0px" }}>
                      <Select
                        placeholder="إختر طريقة الدفع"
                        maxMenuHeight={200}
                        menuPlacement={"top"}
                        isClearable
                        // openMenuOnClick={false}
                        onChange={(value) => {
                          setPaymentMethodId(value && value.value ? value.value : null)
                          setPaymentMethodStr(value && value.label ? value.label : null)
                        }}
                        options={paymentMethods}
                      />
                    </div>

                    <div style={{ margin: "15px 0px 15px 0px" }}>
                      <Select
                        placeholder="إضافة خصم"
                        isClearable
                        maxMenuHeight={200}
                        menuPlacement={"top"}
                        // openMenuOnClick={false}
                        onChange={(value, triggeredAction) => {
                          if (value && value.value) {
                            computeTotal(cartItems, value.value)
                            setDiscountId(value.value)
                          } else { setDiscountId(null) }
                            // setDiscountId(value && value.value ? value.value : null)

                            if (triggeredAction.action == "clear") {
                              setDiscountId(null)
                              computeTotal(cartItems, null)
                            }
                        }}
                        options={discounts}
                      />
                    </div>
                    {/* </div> */}

                    <Button
                      size="large"
                      fullWidth={true}
                      sx={{ fontWeight: 500 }}
                      color={"secondary"}
                      variant="contained"
                      onClick={() => placeOrder()}
                    /*onClick={() => {
                      printReceipt()
              
                    }}*/
                    >
                      الدفع ({cartTotal} ج.م)
                    </Button>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </CacheProvider>
    </ThemeProvider>
  );
}

export default Home;
