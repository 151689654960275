import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_API_URL

export const updateExpenses = async (data) => {
    let url = `${BaseUrl}v1/update-expenses`
    
    return request({
        url,
        method: "PUT",
        data,
    }, true)
}

export const fetchExpenseModes = async (data) => {
    return request({
        url: `${BaseUrl}v1/fetch-expense-modes`
    }, true)
}

export const fetchExpenses = async () => {
    return request({
        url: `${BaseUrl}v1/expenses`
    }, true)
}