import React, { useState, useEffect } from "react"
import Select from "react-select"
import Header from "../components/Header"
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis"
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from "react-query"
import { fetchBranchWarehouse } from "../services"

import {
    Container,
    Grid,
    Table,
    TableHead,
    TextField,
    TableBody,
    TableCell,
    TableRow,
    Paper,
    TableContainer,
} from "@mui/material"
import Spinner from "../components/Spinner";

const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin]
});

const columns = [
    {
        field: 'supplier_name_ar',
        headerName: 'المصنع',
        // description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        // valueGetter: (params) =>
        //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    { field: 'sku', headerName: 'أرتكل', width: 70, type: 'number' },
    { field: 'color_ar', headerName: 'اللون', width: 130 },
    {
        field: 'size',
        headerName: 'المقاس',
        width: 90,
    },
    {
        field: 'branch_qty',
        headerName: 'العدد',
        width: 90,
    },
    {
        field: 'old_price',
        headerName: 'السعر القديم',
        width: 120,
        valueGetter: (params) =>
            `${params.row.old_price} ج.م`
    },
    {
        field: 'new_price',
        headerName: 'السعر الحالي',
        width: 120,
        valueGetter: (params) =>
            `${params.row.old_price} ج.م`
    },
];

function Warehouse() {
    const [filters, setFilters] = useState({}),
        [supplierOptions, setSupplierOptions] = useState([]),
        [skuOptions, setSkuOptions] = useState([]),
        [colorOptions, setColorOptions] = useState([]),
        [sizeOptions, setSizeOptions] = useState([]),
        [variations, setVariations] = useState([]),
        { data, isLoading, refetch } = useQuery("variations", () => fetchBranchWarehouse(filters), {
            select: (data) => data,
            onSuccess: (data) => {
                setVariations(data.variations)
                /*setSupplierOptions(data.supplierFilters.map((s) => {
                    return { label: s.name_ar, value: s.id }
                }))
                setSkuOptions(data.skuFilters.map((s) => {
                    return { label: s.sku, value: s.sku }
                }))
                setColorOptions(data.colorFilters.map((c) => {
                    return { label: c.color_ar, value: c.color_ar }
                }))
                setSizeOptions(data.sizeFilters.map((s) => {
                    return { label: s.size, value: s.size }
                }))*/
            }
        }),
        { data: supportedFilters, isLoading: isFiltersLoading, } = useQuery("supportedFilters", fetchBranchWarehouse, {
            select: (data) => data,
            onSuccess: (data) => {
                setSupplierOptions(data.supplierFilters.map((s) => {
                    return { label: s.name_ar, value: s.id }
                }))
                setSkuOptions(data.skuFilters.map((s) => {
                    return { label: s.sku, value: s.sku }
                }))
                setColorOptions(data.colorFilters.map((c) => {
                    return { label: c.color_ar, value: c.color_ar }
                }))
                setSizeOptions(data.sizeFilters.map((s) => {
                    return { label: s.size, value: s.size }
                }))
            }
        })

    const prepareFilter = (key, value) => {
        let newFilters = { ...filters }
        newFilters[key] = value
        setFilters(newFilters)
    }

    const removeFilter = (key) => {
        let newFilters = {...filters}
        delete newFilters[key]
        setFilters(newFilters)
    }

    useEffect(() => {
        if (filters) {
            refetch()
        }
    }, [filters])

    if (isLoading || isFiltersLoading) return <Spinner />

    return (
        <CacheProvider value={cacheRtl}>
            <Header />

            <Container disableGutters>
                <Grid container direction="row" spacing={5}>
                    <Grid item lg={12}>
                        <h2>المخزن</h2>

                        <Container disableGutters sx={{ mb: 2 }}>
                            <Grid container direction="row" spacing={5}>
                                <Grid item lg={3}>
                                    <Select
                                        options={supplierOptions}
                                        isClearable
                                        placeholder="المصنع"
                                        onChange={(value, triggeredAction) => {
                                            if (value && value.value) prepareFilter("supplier_id", value.value)
                                            
                                            if (triggeredAction.action === "clear") {
                                                removeFilter("supplier_id")
                                            }
                                        }}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: "transparent",
                                                height: "57px"

                                            }),
                                        }}
                                    />
                                </Grid>

                                {/* <Grid item lg={2}>
                                    <TextField
                                        label="الأرتكل"
                                        variant="outlined"
                                        onChange={({ target }) => {
                                            prepareFilter("sku", target.value)
                                        }}
                                        fullWidth={true}
                                    />
                                </Grid> */}

                                <Grid item lg={2}>
                                    <Select
                                        options={skuOptions}
                                        placeholder="الأرتكل"
                                        isClearable
                                        onChange={(value, triggeredAction) => {
                                            if (value && value.value) prepareFilter("sku", value.value)
                                            
                                            if (triggeredAction.action === "clear") {
                                                removeFilter("sku")
                                            }
                                        }}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: "transparent",
                                                height: "57px"

                                            }),
                                        }}
                                    />
                                </Grid>

                                <Grid item lg={3}>
                                    <Select
                                        options={colorOptions}
                                        placeholder="اللون"
                                        isClearable
                                        name="color_filter"
                                        defaultValue={filters.color_ar}
                                        onChange={(value, triggeredAction) => {
                                            if (value && value.value) prepareFilter("color_ar", value.value)
                                            
                                            if (triggeredAction.action === "clear") {
                                                removeFilter("color_ar")
                                            }
                                        }}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: "transparent",
                                                height: "57px"

                                            }),
                                        }}
                                    />
                                </Grid>

                                <Grid item lg={2}>
                                    <Select
                                        options={sizeOptions}
                                        placeholder="المقاس"
                                        isClearable 
                                        onChange={(value, triggeredAction) => {
                                            if (value && value.value) prepareFilter("size", value.value)
                                            
                                            if (triggeredAction.action === "clear") {
                                                removeFilter("size")
                                            }
                                        }}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: "transparent",
                                                height: "57px"

                                            }),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Container>

                        <Paper style={{ height: "680px", width: '100%' }}>
                            <DataGrid
                                rows={variations}
                                columns={columns}
                                getRowClassName={(params) => {
                                    return params.row.branch_qty === 1 ? "orange-table-row" : (params.row.branch_qty === 0 ? `red-table-row` : ``);
                                  }}
                                pageSize={50}
                                rowsPerPageOptions={[50]}
                                pagination
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </CacheProvider>
    )
}

export default Warehouse